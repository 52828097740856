import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <h3>{`Indicateurs hétérogènes, visualisation unique`}</h3>
    <p>{`A partir de 1992 l'Europe a introduit une échelle colorée d'efficacité énergétique sur l'étiquette de chaque appareil électroménager: réfrigérateur, lave-linge, four, climatiseur, ampoule, ... et étendu plus récemment à l'automobile et à l'immobilier.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "115.33742331288343%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAAsSAAALEgHS3X78AAAEfUlEQVQ4y4VVa0ybVRj+/vGfP/40YUImEwobjoRKZl3HxE4GC15CokHIggnZj8UAMSpoDJtEWQWWzG7g3Gxw2YJurgxn6HDQBlAuC4WWa6lbb9R+0H69fb3xeM7pZWMXfZM355zv9Dx5zvO87ykHEsFgEKFQCP8X29vbLJ8VNpsNHAVqbW2FVqtlHzY2NuB0OmGxWOBwOGAwGLC4uIitra30wXg8DlEUEQ6HGRFKiIbdbgdHAerr66FSqdDU1ISGhgbU1taira0NLS0tbF1TU8PWqXC5XBgZGcHY2BgmJyeh0WhgMpkYES4SiWB6epqxoIyWl5cxPz8PnuehVCoxMDAAs9mM1dVVBhaLxbC5uclYpaQSBAE+n4/9hgFSMAq6sLCAmZkZuN1udpjuPR7RaJQdftp3SoYBUrrr6+sMkOpAN6lOKSPoPLWme5QR/U7Z0kxpygCpsFPTU5gzzGF8Yhyuf1yJgzECuh1Pu5oaKaDX632qywyQ0teN6mGaX4TRYMLUxBQWjUs7SuXRkd4oVWJra2swGo1MMqrz0tISKRsxhKu3r+DyzYtQay7hR80PuEbW4wY9PIGtJ+ovdeXGxkZwHAeZTIacnBzsyspiknFCQMCBkyXIrd2FguO52F2XhYoTChyok6J/RJ10NprWjEZnZycDo9nf38/KLTMzk7HlAqIfJy+9jw/OHcFxVSXqvzuKD1XVaO5pxDXdRfgiO1lSA2i9KRQKZGRkoKqqCqWlpcjOzoZhbg6cPyygTl2GyvN7Ud27n2Qx3lRJ0NhRh/eU5bjn+CvBMh5La0iTujo8PMyKenBwEDqdjmnJiVEf+v6sRveYDGf1h3BWJyejHN13ytD1cz30Kz2IxgVCMWFSjLrs8TzbZTEmQG2QQzUtQe9sES7MkJwtRN+sFKcvH8P5MTmC2Ex18RN1mKpZ6jwDjBNAl0UO56oEzrUikvvIvAgb5kJYFqTQDtXCY+ui/u5w+XH3qQwMcJtcJ/S3DMG1lxAyFyBolpB5AcT1PHhXXsHN/ko4lk480mKR/waMiwK2zr0K/ptc8GckJPNZusnc2/kiTEo5fvm8Gv5fmxOAkYeAydJPAJKOSwCGCKBSAf7LYvCnpODbS1i626UQ2ouxcuoIbjWT/Z8+SrZkDEKy9cRwDP5AlEE+ZOgT4FAchHV/PqzSfbCW7E1mIR6QtfvlPRh/qwIDhw4i+NUXTEkh6TLvDcDMryMkUilSDEkvO99+F3b5YTheV8Bx+I102km6ysqxcOwd3Ks4Cr6rh5QNYeXzIxj1Y9m1CpfHi1A4yrRNmOIRsPHca7jP7YaNk8DK5SUzn40Wbg/s3Au4lVcO9fNSeDt64SOXjBO3J5zDGLUOwR/1Em1jBJA8DmHBB2Pzabhau3H/4044PuuC/dNvYf3kDBt9X3+PYEcfHly4gmX1AOIWGwIRkV3ZH/PAH/ekX3LGMERa6MYfWvw2qYdGfxfXyfz6XS2u/j6EG6N34CHFTN/nIGFFYTb9PtisVua0h2gpeAX2PtK/BQr4LzHKDKVOOzVFAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ecolabel",
          "title": "Ecolabel",
          "src": "/static/7ebdf0d92d2084679816f7434555cc57/13ae7/Ecolabel.png",
          "srcSet": ["/static/7ebdf0d92d2084679816f7434555cc57/222b7/Ecolabel.png 163w", "/static/7ebdf0d92d2084679816f7434555cc57/ff46a/Ecolabel.png 325w", "/static/7ebdf0d92d2084679816f7434555cc57/13ae7/Ecolabel.png 350w"],
          "sizes": "(max-width: 350px) 100vw, 350px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Bien entendu les échelles et niveaux sont propres à chaque catégorie d'appareil. Les voitures sont évaluées en niveau de CO2/km, les grille pain en efficacité énergétique ainsi que les climatiseurs mais sur d'autres échelles.
`}<em parentName="p">{`Toutes choses égales par ailleurs`}</em>{`, le résultat est livré au consommateur sur la même échelle à 7 niveaux pour éclairer son choix (ou plus récemment 10 avec des A+, A++ et A+++). `}</p>
    <h3>{`Critique de la visualisation`}</h3>
    <p>{`Plusieurs remarques:`}</p>
    <ul>
      <li parentName="ul">{`l'échelle est représentée par plusieurs grandeurs redondantes: la lettre, la couleur, la taille des barres horizontales, la position. La redondance n'est pas forcément une mauvaise chose, mais là c'est beaucoup. `}<a parentName="li" {...{
          "href": "http://www.edwardtufte.com/tufte"
        }}>{`Tufte`}</a>{` dirait que le rapport quantité donnée/quantité encre est faible. `}</li>
      <li parentName="ul">{`la direction de l'échelle: Les étiquettes des catégories A, B, ... donne une indication sur le sens de lecture: A c'est mieux que B qui est mieux que C, ... La couleur vient confirmer cet implicite. Tout le monde sait que `}<strong parentName="li">{`vert`}</strong>{` c'est mieux que `}<strong parentName="li">{`rouge`}</strong>{`. `}</li>
      <li parentName="ul">{`malgré les barres horizontales qui s'agrandissent, l'échelle n'est pas graduée. Il n'est pas possible de savoir «Combien de fois plus d'énergie pour G que pour A ?»`}</li>
      <li parentName="ul">{`l'aiguille qui indique la valeur est un curseur noir lui même redondant: `}
        <ol parentName="li">
          <li parentName="ol">{`il est positionné en face du niveau de l'échelle `}</li>
          <li parentName="ol">{`il indique la valeur par une lettre en blanc sur noir`}</li>
        </ol>
      </li>
      <li parentName="ul">{`la valeur est paradoxalement moins visible que l'échelle multicolore. Cette faiblesse éclate au grand jour face à plusieurs étiquettes simultanément. Sur la vitrine d'une agence immobilière l'œil aperçoit très bien le petit arc en ciel vert-rouge répété à l'identique sur toutes les annonces sans aucune valeur ajoutée. Mais il faut s'approcher plus près pour lire le petit curseur noir et sa lettre blanche.`}</li>
    </ul>
    <h3>{`Proposition pour une meilleure visualisation`}</h3>
    <p>{`Essayons de formuler une version plus efficace de la vénérable étiquette. Le fil des idées serait le suivant`}</p>
    <ol>
      <li parentName="ol">{`effacer/atténuer l'intensité de la couleur des niveaux de l'échelle qui brouillent la lecture de loin`}</li>
      <li parentName="ol">{`colorer l'aiguille dans la couleur du niveau`}</li>
      <li parentName="ol">{`et ainsi ... utiliser l'échelle comme aiguille !!!`}</li>
    </ol>
    <p>{` `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "471px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "22.085889570552148%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAABAUlEQVQY0zXMv0vDQBiH8f7XbgqCOCi4CLo5CA4uDoK4iJNiqrWkTaqt/WFim6Dlkl7u2kuujY8lxffdPvB9avfDZ7aeTrgNHf5PLxTtsEUvfme5WlVmCoMXenhjD2NNZdZa/KC9dpdFriurhd8DnMHdetxgVdoKp8mIRn+P5mibVLubYD4niJoE8QuZjjbBpSWYdglEB1mkm6AWE1TYQI1fMbO4wrlKEMNH0s8H5MSrrMgNyVcLOXaRUYff9edmjqhfkjnnSOeCUk2pZXGEcF1mvo/q96txrjVpp0v29oH03ynzHC0EP4dHyP0Dkp1drFOnKJckN2eo61Oyq2OKsMcfK1slVAk7nPUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "ecotous",
          "title": "ecotous",
          "src": "/static/57338a9f9a0adbb7174caf16c56f937b/1f09d/ecotous.png",
          "srcSet": ["/static/57338a9f9a0adbb7174caf16c56f937b/222b7/ecotous.png 163w", "/static/57338a9f9a0adbb7174caf16c56f937b/ff46a/ecotous.png 325w", "/static/57338a9f9a0adbb7174caf16c56f937b/1f09d/ecotous.png 471w"],
          "sizes": "(max-width: 471px) 100vw, 471px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Bien plus visible de loin.`}</p>
    <p>{`Et surgit une autre idée. Pourquoi ne pas utiliser la longueur des barres pour ajouter l'information des proportions entre niveaux ?
Dans le cas de l'échelle des bâtiments, la représentation serait:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "316px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.601226993865026%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABvElEQVQoz42QS0sbYRSG528IpV6qRhLjJcEMFkWsC0sX4g1XXbroP3ChERcqkl0p3QfFGE1KaaEmsbVdqIjVGnWMCWEGQYlEv29mMled5rtoDKJIqXl5OJzFub2H4QUeABCJRX1rs5vHB5qsAgjE0sTklJxpmAtLwYrZ3vfJIKUUE0JLEwMhlCXZP+cfXnk3GBua3J4ptpISxABwoWt6dGX540b/1AY7v/9WOP+QkT4RWhyC/7c5m81Kkrz6M7p12B3n3X/S7FrCtiv0oPwJxZDiS0owJagAfWyHkSQRQikQ8OfAK2Q48lozNVzUZInhIXoN+eu7LUNFJ/fceQaabvz4vmzsddFDO+KaMNeIDpyYq8dcHU4M4NQETo5gwUeRVvzG/WZRhIZ5+eVzSAl35IM11qLDWrTfcZNXW8Fn1kKZFbYj/hc6E9DpETrj6dUVRYjJZDIAir8318F0pz5qU8edqrf+AQ2qt7EQx92Kt0kZcxQYtedPdm4OYGRZVlT1azgEXrOm67nGVuueF/+iSvdUFmJLpf6yzuwbQN8izF48nkylQoHATuebhLOVc7VzzW1P4G7fL69Nz/iuAUQe/3RXeg3vAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "pechelle",
          "title": "pechelle",
          "src": "/static/4308581de78084fd5b30d9347fd1f7f0/67145/pechelle.png",
          "srcSet": ["/static/4308581de78084fd5b30d9347fd1f7f0/222b7/pechelle.png 163w", "/static/4308581de78084fd5b30d9347fd1f7f0/67145/pechelle.png 316w"],
          "sizes": "(max-width: 316px) 100vw, 316px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Un logement de niveau G peut être plus de 10 fois plus énergivore qu'un logement de niveau A !!!!
`}<a parentName="p" {...{
        "href": "http://fr.wikipedia.org/wiki/Diagnostic_de_performance_%C3%A9nerg%C3%A9tique"
      }}>{`Sources: wikipedia`}</a></p>
    <h3>{`Proposition pour un meilleur indicateur`}</h3>
    <p>{`Le bon indicateur est conçu pour répondre directement aux questions que se pose le lecteur quand il examine les données.
Dans le cas du logement, les questions brulantes de l'acheteur potentiel sont: «Combien coute le chauffage annuel ?», «Sera t il possible de gagner en ajoutant de l'isolation ?»
Pour 100 m2 si le cout est 300€/an pour un niveau A alors c'est 3000€ pour un niveau G. `}<a parentName="p" {...{
        "href": "http://lexpansion.lexpress.fr/patrimoine/l-etiquette-energetique-dans-l-immobilier_251636.html"
      }}>{`Sources: l'expansion`}</a>{`.
Pour un appareil électroménager, la question clé est: «A partir de combien de temps d'utilisation, l'appareil de niveau A est-il économiquement rentable ?». Il faudrait que l'échelle indique le surcout (ou l'économie) annuel à utiliser un appareil de chaque catégorie. Charge au consommateur de le comparer aux prix de vente.`}</p>
    <h3>{`Conclusion`}</h3>
    <p>{`Quel est le rapport entre les étiquettes énergie et les tableaux de bord de pilotage d'une organisation ?
Deux transpositions directes:`}</p>
    <ul>
      <li parentName="ul">{`Les échelles ne doivent pas masquer la lecture des valeurs. Face à un tableau de bord présentant de multiples indicateurs, il est bien plus facile de voir que `}<strong parentName="li">{`tout est vert`}</strong>{`, plutôt que de vérifier que `}<strong parentName="li">{`toutes les aiguilles noires se trouvent dans les zones vertes`}</strong></li>
      <li parentName="ul">{`La démarche `}<strong parentName="li">{`comment répondre aux questions que se posent le lecteur`}</strong>{` est plus efficace que `}<strong parentName="li">{`comment présenter au mieux les données`}</strong>{`  `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      